@import 'themes';
@import '~flexboxgrid/dist/flexboxgrid.min.css';

@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import './loading.theme';

@include nb-install() {
  @include nb-theme-global();
  // loading progress bar
  @include ngx-loading-theme();
  @include nb-auth-global();
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.col-full {
  width: 100%;
}

.row {
  margin: 1em 0 1em 0;
}

div[class^='col-'] {
  padding: 0.75em;
  // max-width: 100%;
}

blockquote {
  border-left: 6px solid var(--color-primary-default);
  padding-left: 0.5rem;
  margin: 0;
}

.hidden {
  visibility: hidden;
}

.img-responsive {
  width: 100%;
}

[routerLink] {
  cursor: pointer;
}
.cdk-virtual-scroll-orientation-horizontal .cdk-virtual-scroll-content-wrapper {
  display: flex !important;
  flex-direction: row !important;
}
.padding {
  padding: 10px;
}

.margin {
  margin: 10px;
}

.margin-right {
  margin-right: 10px;
}
/* You can add global styles to this file, and also import other style files */
